@use "../../general/settings" as settings;

@import "../../../../base/styles/general/import-general-only";

/* TODO: SPM - ET-1309/ET-1598/ET-1656 - Move the file content into this: _payment-installment-promotion (if you want this feature available in all site label)
   Also delete this file _payment-installment-promotion-redesigned from here  */

$paymentInstallmentPromotion-background: settings.$primary-blue !default;
$paymentInstallmentPromotion-color: settings.$white !default;
$paymentInstallmentPromotion-highLighted-color: settings.$secondary-green !default;
$paymentInstallmentPromotion-links-background: transparent !default;
$paymentInstallmentPromotion-links-color: settings.$white !default;
$paymentInstallmentPromotion-links-hover-background: settings.$white !default;
$paymentInstallmentPromotion-links-hover-color: settings.$tertiary-blue !default;

.paymentInstallmentPromotion-wrapper {
	background: $paymentInstallmentPromotion-background;
}

.paymentInstallmentPromotion {
	width: 100%;
	padding: 2.5em 1.5em;
	text-align: left;

	@include media-breakpoint-up(md) {
		display: flex;
		padding: 1.5em;
	}

	&-iconWrapper {
		display: flex;
		align-items: center;
		width: 50px;

		@include media-breakpoint-up(md) {
			width: 100px;
			margin-top: -15px;
		}
	}

	&-iconBg, &-icon {
		width: 50px;
		height: 50px;
		margin: 0;

		@include media-breakpoint-up(md) {
			width: 100px;
			height: 100px;
		}
	}

	&-iconBg {
		content: '';
		background: rgba(settings.$white, 0.1);
		border-radius: 50%;
	}

	&-icon {
		display: inline-block;
	}

	&-texts {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 20px;

		@include media-breakpoint-up(md) {
			padding-top: 0;
			padding-left: 30px;
		}
	}

	&-title {
		padding: 0;
		margin-bottom: 0.5em;
		font-size: 1.2em;
		line-height: 1.2em;
		color: $paymentInstallmentPromotion-color;

		@include media-breakpoint-up(md) {
			margin-bottom: 0.3em;
			font-size: 1.4em;
		}

		.highLightedText {
			color: $paymentInstallmentPromotion-highLighted-color;
		}

		@include sf_pro_textregular;
	}

	&-description {
		padding: 0;
		margin: 0;
		font-size: 1em;
		line-height: 1.2em;
		color: $paymentInstallmentPromotion-color;

		@include sf_pro_textlight;
	}

	&-buttons {
		margin-top: 35px;

		@include media-breakpoint-up(md) {
			margin-top: 15px;
		}

		A {
			display: inline-block;
			padding: 0.6em 1em;
			margin-right: 15px;
			margin-bottom: 5px;
			font-size: 1em;
			color: $paymentInstallmentPromotion-links-color;
			text-align: center;
			text-decoration: none;
			background: $paymentInstallmentPromotion-links-background;
			border-radius: 5px;
			transition: all 0.3s;

			@include sf_pro_textsemibold;

			&:hover, &.seeMatches {
				color: $paymentInstallmentPromotion-links-hover-color;
				background: $paymentInstallmentPromotion-links-hover-background;
			}
		}
	}
}
