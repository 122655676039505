
$travelBenefits-background: $white !default;
$travelBenefits-item-background: $white !default;
$travelBenefits-item-border-color: $white !default;
$travelBenefits-item-hover-border-color: $primary-blue-light !default;
$travelBenefits-color: $gray-text-default !default;
$travelBenefits-link-color: $sp-secondary-color !default;

.travelBenefits-wrapper {

	padding: 0 15px;

	@include media-breakpoint-up(md) {
		padding: 0;
	}

	.travelBenefits {

		padding: 20px 0;
		background: $travelBenefits-background;

		.flex-container {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
		}

		.travelBenefits-item-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 5px;

			.travelBenefits-item {
				display: flex;
				width: 100%;
				padding: 1em;
				cursor: pointer;
				background: $travelBenefits-item-background;
				border: 1px solid $travelBenefits-item-border-color;
				border-radius: 10px;
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

				&:hover, &:focus {
					border: 1px solid $travelBenefits-item-hover-border-color;
					border-radius: 10px;
				}

				&-iconWrapper {
					position: relative;
					display: block;
					width: 60px;
					padding: 0;
					margin: 0;
					overflow: hidden;

					&-icon {
						display: inline-block;
						width: 60px;
						height: 60px;
						margin: 3px 0 0 0;
					}
				}

				&-texts {
					display: flex;
					flex: 1;
					flex-direction: column;
					justify-content: center;
					padding: 0 0 0 20px;
					font-size: 14px;

					&-title {
						display: block;
						padding: 0;
						margin: 0 0 0.3em 0;
						font-size: 1.2em;
						line-height: 1.4em;
						color: $travelBenefits-color;

						@include sf_pro_textregular;
					}

					&-description {
						display: block;
						padding: 0;
						margin: 0;
						font-size: 1em;
						line-height: 1.4em;
						color: $travelBenefits-color;

						@include sf_pro_textlight;
					}

				}
			}
		}

		&-linkWrapper {
			display: block;
			width: 100%;
			margin: 20px 0 0 0;
			text-align: right;

			a {
				display: inline-block;
				padding: 0;
				font-size: 1em;
				line-height: 1.4em;
				color: $travelBenefits-link-color;
				text-decoration: none;

				@include sf_pro_textsemibold;

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

