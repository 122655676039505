@use "../../../../base/styles/general/settings";

$sub-header-title-color: settings.$gray-text-default !default;

.header {
	.title {
		padding: 0;
		margin: 20px 0;
		font-size: 24px;
		line-height: 1.4em;
		color: $sub-header-title-color;
		text-align: center;
        
		@include sf_pro_textsemibold;

		@include media-breakpoint-up(md) {
			font-size: 40px;
		}
	}
}