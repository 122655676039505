$eventTypes-background: $white !default;
$eventTypes-color: $white !default;
$eventTypesItem-gradient-color: $sp-secondary-color !default;

.eventTypes-wrapper {
	padding: 0 15px;

	@include media-breakpoint-up(md) {
		padding: 0
	}

	.eventTypes {
		padding: 20px 0;
		background: $eventTypes-background;

		.flex-container {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
		}

		.eventTypesItem-container {
			@include media-breakpoint-down(md) {
				padding: 0;
			}

			.eventTypesItem {
				position: relative;
				display: block;
				width: 100%;
				margin-bottom: 10px;
				overflow: hidden;
				text-decoration: none;
				border-radius: 10px;

				@include media-breakpoint-up(md) {
					min-height: 180px;
				}

				&:hover, &:focus {
					text-decoration: none;
				}

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					display: block;
					width: 100%;
					height: 100%;
					content: '';
					background: linear-gradient(to bottom, transparent, $eventTypesItem-gradient-color);
					opacity: 0.6;
				}

				.eventTypesItem-iconWrapper {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					display: block;
					width: 100%;
					height: 100%;
					padding: 0;
					margin-top: 2px;
					overflow: hidden;
				}

				.eventTypesItem-icon {
					width: 100%;
					margin: 0;

					@include media-breakpoint-up(md) {
						height: 100%;
					}
				}

				.eventTypesItem-texts {
					position: relative;
					z-index: 3;
					display: block;
					padding: 20px 20px 0 20px;
					margin: 0;
					color: $eventTypes-color;

					@include media-breakpoint-up(md) {
						min-height: 180px;
						padding: 80px 30px 0 30px;
					}
				}

				.eventTypesItem-title {
					display: block;
					padding: 0;
					margin: 0;
					font-size: 2em;
					line-height: 1.4em;

					@include sf_pro_textsemibold;
				}

				.eventTypesItem-description {
					display: block;
					height: 2.8em;
					padding: 0;
					margin: 0;
					overflow: hidden;
					font-size: 1.1em;
					line-height: 1.4em;

					@include sf_pro_textregular;
				}
			}
		}
	}
}