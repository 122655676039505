@import "../../../../base/styles/general/import-general-only";

.trust-increase-block {
	margin-top: 60px;

	.partners-holder {
		background-color: $la_dk-primary-background;

		.partners {
			max-width: 1000px;
			padding-top: 40px;
			margin: 0 auto;

			@include media-breakpoint-up(md) {
				padding-bottom: 40px
			}
		}
	}

	img,
	.description {
		margin: 0 auto;
	}

	.description {
		max-width: 75%;
		font-size: 14px;
		color: $la_dk-secondary-color;
	}
}

.trust-pilot-review-promotion {
	height: 110px;

	@include media-breakpoint-up(md) {
		height: 150px;
	}

	.trust-pilot-reviews-text {
		align-content: center;
		margin: 0 auto;
	}

	i {
		color: $secondary-green
	}
}