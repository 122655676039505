@import "../../base/styles/general/import-general-only";
@import "node_modules/bootstrap/scss/card";
@import "../../base/styles/general/messages/payment-installments-message";

BODY {
	header {
		.background-image-container {
			.background-img.background-img {
				@include media-breakpoint-down(md) {
					left: 0
				}

				@include media-breakpoint-down(sm) {
					left: -30%
				}
			}
		}
	}

	MAIN {
		position: relative;
	}

	.homepage {
		&,
		.container.popular_events .row {
			@import "layout/homepage/header-title";
		}

		.header {
			background: none !important;

			.title {
				font-size: 32px;
				text-align: left;

				@include media-breakpoint-up(md) {
					text-align: center;
				}
			}
		}

		.container {
			margin: 0 auto;

			@include responsiveContainerSizeFixer();
		}

		@import "layout/homepage/event-types";

		.howItWorks-wrapper {
			@import "../../base/styles/layout/homepage/how-it-works";
		}

		.allInOneProtectionBlock {
			margin: 25px auto;

			@import "../../base/styles/layout/common/all-in-one-protection";
		}

		/* TODO: SPM - ET-1309/ET-1598/ET-1656 - If we want this feature to working on all labels, then copy&paste the new design into this file, and please import it here below: payment-installment-promotion; */
		@import "../../base/styles/layout/common/payment-installment-promotion-redesigned";

		.container.offers {
			.row {
				margin: 0;

				@import "../../base/styles/layout/homepage/offers";
			}
		}

		@import "../../base/styles/layout/homepage/lifetime-events";

		.lifetime-events-block {
			.promotionTitle {
				i {
					display: none
				}
			}
		}

		.container.giftcard {
			.row {
				margin: 40px 0 0 0;

				@import "../../base/styles/layout/homepage/giftcard";
			}
		}

		.container.popular_events {
			.row {
				margin: 0;

				@import "../../base/styles/layout/homepage/popular-events";
			}
		}

		.container.arrangement_of_the_week {
			margin: 20px auto 0 auto;

			@import "../../base/styles/layout/homepage/arrangement-of-the-week";
		}

		@import "layout/homepage/travel-benefits";

		.mobile_newsletter {
			@import "../../base/styles/layout/common/mobile-newsletter";

			@include mobile_newsletter();
		}

		.container.reviews{
			@import "../../base/styles/layout/homepage/reviews";
		}

		.container.calendar {
			padding-bottom: 25px;

			@import "../../base/styles/layout/homepage/calendar";
		}

		@import "../../base/styles/layout/homepage/homepage-faq";
		@import "../../base/styles/layout/homepage/promoted-blog-post";

		.container.media_partners {
			@import "../../base/styles/layout/homepage/media-partners-presentation-type";
			
			.header {
				background: none;
			}
		}
	}

	@import "../../base/styles/layout/common/modals/emptymonth-newsletter-modal";
	@import "../../base/styles/layout/common/custom-tooltip-rules";

	.modal.video .modal-dialog {
		max-width: 600px;

		.modal-body {
			text-align: center;
		}
	}
}